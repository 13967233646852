<template>
	<div class="not-found-error-page">
		<div class="not_found_message">
			<div class="not_found-message__image">
                <span class="iconify" data-icon="emojione:framed-picture"></span>
			</div>
			<div class="not_found-message__title">
				Oops! Page not found
			</div>
			<div class="not_found-message__message">
				We're sorry, but this calendar is not found.
			</div>
		</div>
		<b-button type="is-info" expanded @click="toCalendarList">
			To Calendar List
		</b-button>
	</div>
</template>

<script>

export default {
	name: 'NotFoundMessage',
	methods: {
		toCalendarList(){
			this.$router.replace({name: "calendar-list"})
		},
	},
};
</script>

<style scoped>
.iconify{
	height: 105px;
    width: 170px;
}
.not-found-error-page{
	height: calc(100% - 50px);
	padding: 16px;
	display: flex;
	flex-direction: column;
}

.not_found_message{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	gap: 5px;
}
.not_found-message__title{
	font-size: 18px;
	font-weight: 600;
}

.not_found-message__message{
	font-size: 14px;
	text-align: center;
}

.not_found-access{
	text-decoration: underline;
}
</style>